<template>
  <div style="margin: 20px">
    <div>
      <p class="font-bold text-lg" style="margin-left: 10px">
        Activity Idea List
      </p>
    </div>

    <div class="flex justify-between" style="margin-top: 20px">
      <div style="display: flex; margin-left: 10px">
        <p
          :class="
            selectDataType == 'inspirations' ? 'query-data-select' : 'query-data-normal'
          "
          @click="showInspirations"
        >
        Get Inspirations
        </p>
        <p
          :class="
            selectDataType == 'all' ? 'query-data-select' : 'query-data-normal'
          "
          @click="showAllData"
          style="margin-left: 18px"
        >
          All
        </p>
        <p
          :class="
            selectDataType == 'starred'
              ? 'query-data-select'
              : 'query-data-normal'
          "
          style="margin-left: 18px"
          @click="showMyStarredActivities"
        >
          My Starred Activities
        </p>
        <p
          :class="
            selectDataType == 'contest'
              ? 'query-data-select'
              : 'query-data-normal'
          "
          style="margin-left: 18px"
          @click="showContestActivities"
        >
        Contest
        </p>
      </div>

      <div style="display: flex">
        <el-select
          v-model="selectCategory"
          placeholder="Select category"
          @change="doSearch"
          style="width: 300px"
        >
          <el-option
            v-for="(category, index) in categories"
            :key="index"
            :label="category.name"
            :value="category.name"
          ></el-option>
        </el-select>
        <el-input
          v-model="searchWord"
          placeholder="Please input key word"
        ></el-input>
      </div>
    </div>

    <div
      class="bg-white py-3 px-4 mt-3"
      style="margin: 12px 10px 0; border-radius: 5px"
    >
      <p class="text-sm">
        This page lists potential activity ideas that can potentially inspire
        you. We are updating this list as we see more creative examples from
        open sources.
      </p>
    </div>
    <div>
      <div v-if="showDataType == 'normal' && selectDataType == 'inspirations'" style=";display: flex;flex-direction: row;align-items: center;justify-content: center;margin-top: 25px;" >
        <img src="../../assets//image/icon_refresh.png" style="width: 32px;height: 32px;cursor: pointer;" @click="showInspirations">
        <p class="font-bold text-lg" style="margin-left: 5px;color: #ff6450;cursor: pointer;" @click="showInspirations">Give me some new inspirations</p>
      </div>
      <div v-if="dataList.length > 0" class="flex flex-wrap">
        <div
          v-for="(item, index) in dataList"
          :key="index"
          class="activity-idea my-4"
        >
          <activity-idea
            :info="item"
            :index="index"
            :page-count="(pageNum - 1) * pageSize"
            @changeStarStatus="changeStarStatus(item, index)"
            @view="viewOption(item)"
          />
        </div>
      </div>


      <div v-if="dataList.length > 0 && showDataType == 'normal' && selectDataType == 'inspirations'" style=";display: flex;flex-direction: row;align-items: center;justify-content: center;margin-top: 15px;" >
        <img src="../../assets//image/icon_refresh.png" style="width: 32px;height: 32px;cursor: pointer;" @click="showInspirations">
        <p class="font-bold text-lg" style="margin-left: 5px;color: #ff6450;cursor: pointer;" @click="showInspirations">Give me some new inspirations</p>
      </div>

      <el-empty
        description="No Data Yet"
        v-if="dataList.length === 0"
      ></el-empty>
    </div>

    <el-pagination
      v-if="dataList.length > 0 && showDataType == 'normal' && selectDataType!='inspirations'"
      :current-page="pageNum"
      :page-sizes="[10, 50, 100]"
      :page-size="pageSize"
      :total="total"
      style="margin-top: 20px"
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />

    <el-dialog :visible.sync="showRegisterDialog" width="40%">
      <div style="width: 100%; text-align: center">
        <p
          style="
            color: #333333;
            font-size: 24px;
          "
        >
          You need to register to view this activity.
        </p>
        <el-button
          type="primary"
          style="width: 100px; margin-top: 50px"
          @click="gotoRegister"
          >OK</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="showOptionDialog"
      width="60%"
      title="Activity Details"
    >
      <div style="margin: 10px">
        <el-form v-model="optionInfo" label-position="top">
          <el-form-item label="Activity Name">
            <p class="activity-name text-base font-bold mb-4">
              {{ optionInfo.name }}
            </p>
          </el-form-item>
          <el-form-item label="Type">
            <div>
              <el-tag
                v-for="(type, index) in formatOptionTypes(optionInfo.type)"
                :key="index"
                class="mr-3 mb-2"
                >{{ type }}
              </el-tag>
            </div>
          </el-form-item>
          <el-form-item label="Introduction">
            <p
              class="activity-name text-base font-bold mb-4"
              style="line-height: 1.8rem"
            >
              {{ optionInfo.intro }}
            </p>
          </el-form-item>
          <el-form-item
            label="Tags"
            v-if="optionInfo.tags && optionInfo.tags.length > 0"
          >
            <div>
              <el-tag
                v-for="(tag, index) in optionInfo.tags"
                :key="index"
                class="mr-3 mb-2"
                >{{ tag }}
              </el-tag>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  checkRegistration,
  getActivityOptionsByPageV1,
  starActivityOptions,
  getTypes,
  searchActivityOptions,
} from "../../api/eae";
import VueTagsInput from "@johmun/vue-tags-input";
import ActivityIdea from "../../components/ActivityIdea.vue";
import { getUserId,getProjectCode } from "../../utils/store";
import { Loading } from "element-ui";

export default {
  name: "index",
  components: {
    VueTagsInput,
    ActivityIdea,
  },
  data() {
    return {
      showDataType: "normal",
      user_id: getUserId(),
      searchWord: "",
      selectCategory: "",
      categories: [],
      selectDataType: "inspirations",
      dataList: [],
      tag: "",
      temp_tag: [],
      tags: [],
      optionInfo: {
        id: "",
        type: "",
        name: "",
        org: "",
        intro: "",
      },
      showOptionDialog: false,
      showRegisterDialog: false,
      pageSize: 10,
      pageNum: 1,
      total: 0,
    };
  },
  watch: {
    searchWord: function (newVal, oldVal) {
      this.doSearch(newVal);
    },
  },
  mounted() {
    this.doCheckRegistration();
  },
  methods: {
    doSearch(e) {
      this.selectDataType = 'other'
      let loadingInstance = Loading.service({
        fullscreen: true,
        text: "Search...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
      let category = this.selectCategory;
      if (category == "All") {
        category = "";
      }
      searchActivityOptions(this.user_id, this.searchWord, category).then(
        (res) => {
          this.showDataType = "bySearch";
          this.handleResult(res.data.data);
          loadingInstance.close();
        }
      );
    },

    showInspirations(){
      this.selectDataType = "inspirations";
      this.pageNum = 1;
      this.fetchData();
    },
    showContestActivities(){
      this.selectDataType = "contest";
      this.pageNum = 1;
      this.fetchData();
    },

    showMyStarredActivities() {
      this.selectDataType = "star";
      this.pageNum = 1;
      this.fetchData();
    },
    showAllData() {
      this.selectDataType = "all";
      this.pageNum = 1;
      this.fetchData();
    },

    changeStarStatus(item, index) {
      let status = "no";
      if (item.star_status == "no") {
        status = "star";
      }
      let user_id = getUserId();
      let option_id = item._id.$id;
      starActivityOptions(user_id, option_id, status).then((res) => {
        let result = res.data;
        if (result.code == 0) {
          if (status == "no") {
            this.$message.success("Star cancelled");
          } else {
            this.$message.success("Star success~");
          }
          this.fetchData();
        } else {
          this.$message.error(result.msg);
        }
      });
    },

    handleSizeChange(e) {
      this.pageSize = e;
      this.pageNum = 1;
      this.fetchData();
    },
    handleCurrentChange(e) {
      this.pageNum = e;
      this.fetchData();
    },

    doCheckRegistration() {
      let user_id = getUserId();
      checkRegistration(user_id).then((res) => {
        let result = res.data;
        if (result.code == 0) {
          this.showInspirations()
          this.requestAllCategories();
        } else {
          this.showRegisterDialog = true;
        }
      });
    },
    requestAllCategories() {
      getTypes().then((res) => {
        let result = [
            ...res.data.data.filter((item) => item.name !== "Others"),
            ...res.data.data.filter((item) => item.name === "Others"),
          ];
        this.categories = result
        this.categories.unshift({
          name: "All",
        });
      });
    },
  

    gotoRegister() {
      this.showRegisterDialog = false;
      if(getUserId() == '61cd6856a4640b946f8b456f' || getUserId() == '64b0f34ca4640b167b8b45b2' || getUserId() == '654de577a4640bc4308b45ee' ){
        this.$router.push("/home/universal-pass");
        return
      }
      this.$router.push("/home/registration");
    },
    fetchData() {
      let loadingInstance = Loading.service({
        fullscreen: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
      let user_id = getUserId();
      getActivityOptionsByPageV1(user_id, this.pageSize, this.pageNum,this.selectDataType).then(
        (res) => {
          let result = res.data.data;
          this.total = result.total;
          this.handleResult(result.options);
          this.showDataType = "normal";
          loadingInstance.close();
        }
      );
    },
    viewOption(row) {
      this.showOptionDialog = true;
      this.optionInfo = row;
      // this.$router.push({
      //   path: "/home/activityOptionsView",
      //   query: {
      //     id: row._id.$id,
      //   },
      // });
    },
    formatOptionTypes(type) {
       let types = [];
      if (type && type != null && type != "") {
        type.forEach((item) => {
          let temp = item.split("&");
          for (let i = 0; i < temp.length; i++) {
            types.push(temp[i]);
          }
        });
      }
      return types
    },
    handleResult(result) {
      this.dataList = [];
      for (let i = 0; i < result.length; i++) {
        let content = result[i];
        if (content.contest && content.contest == true) {
          if (content.tags) {
            content.tags.push("Contest");
          } else {
            let tags = [];
            tags.push("Contest");
          }
        }
        this.dataList.push(content);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.query-data {
}

.query-data-select {
  //.query-data();
  background: #fff8f7;
  border: 2px solid #ff6450;
  color: #ff6450;
  cursor: pointer;
  text-align: center;
  min-width: 120px;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 15px;
}

.query-data-normal {
  min-width: 120px;
  padding: 10px;
  font-size: 15px;
  font-weight: bold;
  color: #999999;
  border-radius: 5px 5px 5px 5px;
  border: 2px solid #cccccc;
  cursor: pointer;
  text-align: center;
}

.activity-idea {
  width: calc(33% - 20px);
  margin: 16px 10px;
}

.activity-name {
  color: #333333;
}

/deep/ .el-form--label-top .el-form-item__label {
  padding: 0px 0px 0px 0px;
}

</style>
